"use client";

import Image from "next/image";
import Link from "next/link";

import hks from "../../public/assets/hks.webp";
import hkd from "../../public/assets/hkd.webp";

export default function MobileFooter({ dict }: { dict: any }) {
	return (
		<footer className="mobileFooter">
			<div className="disclaimer">
				<svg
					viewBox="0 0 183 76"
					className="logo"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0 76V0H21.0055V29.0278H47.2624V0H68.2679V76H47.2624V48.5556H21.0055V76H0Z"
						fill="#4C4C4C"
					/>
					<path
						d="M74.1074 18.6094V0H123.562V18.6094H107.86V65.36H89.8103V18.6094H74.1074Z"
						fill="#9D2B2B"
					/>
					<path
						d="M150.602 18.6094H146.541V31.2276H151.143C153.73 31.2276 155.746 30.6526 157.19 29.5028C158.694 28.3529 159.446 26.5979 159.446 24.2377C159.446 22.3011 158.634 20.8789 157.009 19.9711C155.445 19.0633 153.309 18.6094 150.602 18.6094ZM128.491 65.36V0H149.609C155.144 0 160.017 0.786742 164.229 2.36022C168.5 3.87319 171.84 6.23341 174.246 9.44089C176.713 12.6484 177.946 16.7636 177.946 21.7867C177.946 23.9048 177.736 26.144 177.314 28.5042C176.954 30.8039 176.141 33.0129 174.878 35.131C173.855 36.765 172.531 38.3082 170.907 39.7607C169.283 41.1526 167.237 42.3327 164.77 43.301L183 65.36H161.792L146.541 45.752V65.36H128.491Z"
						fill="#9D2B2B"
					/>
					<path
						d="M74.1074 74.416V68.7979H74.8402V71.2168H77.9968V68.7979H78.7296V74.416H77.9968V71.8801H74.8402V74.416H74.1074Z"
						fill="#4C4C4C"
					/>
					<path
						d="M80.5146 69.6953C80.3696 69.6953 80.2488 69.6511 80.1522 69.5626C80.0609 69.4742 80.0153 69.3597 80.0153 69.2193C80.0153 69.0788 80.0609 68.9644 80.1522 68.876C80.2435 68.7875 80.3643 68.7433 80.5146 68.7433C80.6595 68.7433 80.7776 68.7875 80.8689 68.876C80.9655 68.9644 81.0138 69.0788 81.0138 69.2193C81.0138 69.3597 80.9655 69.4742 80.8689 69.5626C80.7776 69.6511 80.6595 69.6953 80.5146 69.6953ZM80.1603 74.416V70.3741H80.8689V74.416H80.1603Z"
						fill="#4C4C4C"
					/>
					<path
						d="M83.9603 71.0218C83.7133 71.0218 83.4879 71.0816 83.2839 71.2012C83.0852 71.3209 82.9269 71.4847 82.8088 71.6928C82.6907 71.9009 82.6316 72.1402 82.6316 72.4107C82.6316 72.6916 82.688 72.9413 82.8007 73.1598C82.9188 73.3782 83.0772 73.5473 83.2758 73.6669C83.4744 73.7866 83.7026 73.8464 83.9603 73.8464C84.2233 73.8464 84.4568 73.7866 84.6608 73.6669C84.8702 73.5473 85.0339 73.3782 85.152 73.1598C85.2755 72.9413 85.3372 72.6916 85.3372 72.4107C85.3372 72.1402 85.2755 71.9009 85.152 71.6928C85.0339 71.4847 84.8702 71.3209 84.6608 71.2012C84.4568 71.0816 84.2233 71.0218 83.9603 71.0218ZM85.3534 74.0571V73.5733H85.3292C85.1789 73.8386 84.9803 74.0493 84.7333 74.2053C84.4864 74.3562 84.1911 74.4316 83.8475 74.4316C83.4664 74.4316 83.1282 74.3458 82.8329 74.1741C82.5377 74.0025 82.3068 73.7632 82.1404 73.4563C81.974 73.1493 81.8908 72.793 81.8908 72.3873C81.8908 71.9711 81.9767 71.607 82.1485 71.2949C82.3203 70.9775 82.5538 70.7305 82.849 70.5536C83.1497 70.3767 83.4852 70.2883 83.8556 70.2883C84.2045 70.2883 84.5025 70.3611 84.7494 70.5068C85.0017 70.6472 85.195 70.8527 85.3292 71.1232H85.3534V70.3741H86.0539V74.0103C86.0539 74.6605 85.8848 75.1547 85.5466 75.4928C85.2138 75.8309 84.7387 76 84.1213 76C83.9066 76 83.7106 75.9896 83.5335 75.9688C83.3617 75.948 83.206 75.9116 83.0665 75.8595L83.1309 75.2821C83.2597 75.3238 83.3939 75.3524 83.5335 75.368C83.6784 75.3836 83.8341 75.3914 84.0005 75.3914C84.2797 75.3914 84.5186 75.3472 84.7172 75.2587C84.9212 75.1755 85.0769 75.035 85.1842 74.8374C85.297 74.6397 85.3534 74.3796 85.3534 74.0571Z"
						fill="#4C4C4C"
					/>
					<path
						d="M90.3665 72.2546C90.3665 72.0101 90.3289 71.7943 90.2537 71.607C90.1786 71.4197 90.0578 71.2741 89.8914 71.17C89.7303 71.0608 89.5183 71.0062 89.2552 71.0062C88.8687 71.0062 88.5788 71.1232 88.3856 71.3573C88.1923 71.5914 88.0957 71.9087 88.0957 72.3092V74.416H87.387V68.4858H88.0876V71.0296H88.1118C88.2245 70.8059 88.3936 70.6264 88.6191 70.4912C88.8445 70.3559 89.121 70.2883 89.4485 70.2883C89.8296 70.2883 90.1383 70.3637 90.3745 70.5146C90.6161 70.6654 90.7906 70.8709 90.8979 71.131C91.0107 71.3859 91.067 71.6772 91.067 72.0049V74.416H90.3665V72.2546Z"
						fill="#4C4C4C"
					/>
					<path
						d="M93.7058 69.5314V68.7979H97.8368V69.5314H96.1457V74.416H95.413V69.5314H93.7058Z"
						fill="#4C4C4C"
					/>
					<path
						d="M99.7875 70.9671C99.4762 70.9671 99.2051 71.066 98.9742 71.2637C98.7488 71.4561 98.6146 71.7448 98.5716 72.1298H100.971C100.955 71.9113 100.896 71.7162 100.794 71.5446C100.697 71.3729 100.563 71.235 100.391 71.131C100.225 71.0218 100.024 70.9671 99.7875 70.9671ZM101.358 74.2834C101.175 74.3614 100.977 74.416 100.762 74.4472C100.553 74.4836 100.338 74.5018 100.118 74.5018C99.6399 74.5018 99.2292 74.416 98.8857 74.2444C98.5475 74.0727 98.2844 73.8282 98.0965 73.5109C97.914 73.1884 97.8227 72.8086 97.8227 72.3717C97.8227 71.9763 97.9033 71.6226 98.0643 71.3105C98.2254 70.9932 98.4535 70.7435 98.7488 70.5614C99.044 70.3793 99.393 70.2883 99.7956 70.2883C100.112 70.2883 100.389 70.3455 100.625 70.46C100.861 70.5744 101.054 70.7279 101.205 70.9203C101.355 71.1128 101.465 71.3235 101.535 71.5524C101.61 71.7812 101.648 72.0127 101.648 72.2468C101.648 72.3092 101.645 72.3743 101.64 72.4419C101.634 72.5043 101.629 72.5589 101.624 72.6058H98.5877C98.6199 72.8658 98.7031 73.0947 98.8373 73.2924C98.9716 73.4849 99.1541 73.6357 99.3849 73.745C99.6158 73.8542 99.8922 73.9088 100.214 73.9088C100.418 73.9088 100.609 73.8932 100.786 73.862C100.969 73.8256 101.138 73.7762 101.293 73.7138L101.358 74.2834Z"
						fill="#4C4C4C"
					/>
					<path
						d="M103.343 72.3326V74.416H102.635V70.3741H103.335V71.053H103.36C103.472 70.8241 103.639 70.6394 103.859 70.499C104.079 70.3585 104.35 70.2883 104.672 70.2883C105.069 70.2883 105.386 70.3741 105.622 70.5458C105.864 70.7175 106.036 70.9463 106.138 71.2324C106.304 70.8787 106.513 70.6342 106.766 70.499C107.023 70.3585 107.3 70.2883 107.595 70.2883C107.971 70.2883 108.274 70.3637 108.505 70.5146C108.736 70.6654 108.905 70.8709 109.012 71.131C109.125 71.3859 109.182 71.6772 109.182 72.0049V74.416H108.473V72.278C108.473 72.0335 108.435 71.8177 108.36 71.6304C108.29 71.4431 108.178 71.2975 108.022 71.1934C107.866 71.0842 107.657 71.0296 107.394 71.0296C107.018 71.0296 106.736 71.1466 106.548 71.3807C106.36 71.6148 106.267 71.9321 106.267 72.3326V74.416H105.558V72.278C105.558 72.0335 105.52 71.8177 105.445 71.6304C105.375 71.4431 105.26 71.2975 105.099 71.1934C104.943 71.0842 104.737 71.0296 104.479 71.0296C104.098 71.0296 103.813 71.1466 103.625 71.3807C103.437 71.6148 103.343 71.9321 103.343 72.3326Z"
						fill="#4C4C4C"
					/>
					<path
						d="M112.436 73.862C112.699 73.862 112.932 73.8022 113.136 73.6825C113.346 73.5577 113.512 73.3912 113.636 73.1832C113.759 72.9751 113.821 72.7332 113.821 72.4575C113.821 72.1766 113.759 71.9295 113.636 71.7162C113.512 71.4977 113.346 71.3287 113.136 71.209C112.932 71.0842 112.699 71.0218 112.436 71.0218C112.173 71.0218 111.931 71.0842 111.711 71.209C111.496 71.3287 111.327 71.4977 111.204 71.7162C111.08 71.9295 111.019 72.1766 111.019 72.4575C111.019 72.728 111.08 72.9699 111.204 73.1832C111.327 73.3912 111.496 73.5577 111.711 73.6825C111.931 73.8022 112.173 73.862 112.436 73.862ZM110.302 75.8986V70.3741H111.003V71.131H111.027C111.172 70.8709 111.376 70.6654 111.639 70.5146C111.902 70.3637 112.208 70.2883 112.557 70.2883C112.965 70.2883 113.316 70.3767 113.612 70.5536C113.912 70.7305 114.143 70.9802 114.304 71.3027C114.47 71.62 114.554 71.9893 114.554 72.4107C114.554 72.8216 114.462 73.1858 114.28 73.5031C114.103 73.8152 113.861 74.0597 113.555 74.2365C113.255 74.4134 112.916 74.5018 112.541 74.5018C112.208 74.5018 111.907 74.429 111.639 74.2834C111.376 74.1325 111.174 73.927 111.035 73.6669H111.011V75.8986H110.302Z"
						fill="#4C4C4C"
					/>
					<path
						d="M117.237 70.9671C116.926 70.9671 116.655 71.066 116.424 71.2637C116.198 71.4561 116.064 71.7448 116.021 72.1298H118.421C118.405 71.9113 118.346 71.7162 118.244 71.5446C118.147 71.3729 118.013 71.235 117.841 71.131C117.675 71.0218 117.473 70.9671 117.237 70.9671ZM118.807 74.2834C118.625 74.3614 118.426 74.416 118.212 74.4472C118.002 74.4836 117.787 74.5018 117.567 74.5018C117.09 74.5018 116.679 74.416 116.335 74.2444C115.997 74.0727 115.734 73.8282 115.546 73.5109C115.364 73.1884 115.272 72.8086 115.272 72.3717C115.272 71.9763 115.353 71.6226 115.514 71.3105C115.675 70.9932 115.903 70.7435 116.198 70.5614C116.494 70.3793 116.843 70.2883 117.245 70.2883C117.562 70.2883 117.838 70.3455 118.075 70.46C118.311 70.5744 118.504 70.7279 118.654 70.9203C118.805 71.1128 118.915 71.3235 118.985 71.5524C119.06 71.7812 119.097 72.0127 119.097 72.2468C119.097 72.3092 119.095 72.3743 119.089 72.4419C119.084 72.5043 119.079 72.5589 119.073 72.6058H116.037C116.07 72.8658 116.153 73.0947 116.287 73.2924C116.421 73.4849 116.604 73.6357 116.835 73.745C117.065 73.8542 117.342 73.9088 117.664 73.9088C117.868 73.9088 118.059 73.8932 118.236 73.862C118.418 73.8256 118.587 73.7762 118.743 73.7138L118.807 74.2834Z"
						fill="#4C4C4C"
					/>
					<path
						d="M120.085 74.416V70.3741H120.777V71.053H120.801C120.914 70.8085 121.091 70.6212 121.333 70.4912C121.574 70.3559 121.875 70.2883 122.235 70.2883L122.202 71.0842C121.692 71.079 121.33 71.196 121.115 71.4353C120.9 71.6694 120.793 72.0101 120.793 72.4575V74.416H120.085Z"
						fill="#4C4C4C"
					/>
					<path
						d="M125.441 72.3639C125.344 72.4159 125.21 72.4679 125.038 72.5199C124.872 72.5667 124.684 72.6083 124.475 72.6448C124.158 72.702 123.916 72.7826 123.75 72.8867C123.589 72.9855 123.508 73.1493 123.508 73.3782C123.508 73.5863 123.586 73.7424 123.742 73.8464C123.898 73.9452 124.083 73.9947 124.297 73.9947C124.491 73.9947 124.662 73.9608 124.813 73.8932C124.968 73.8204 125.097 73.7268 125.199 73.6123C125.307 73.4927 125.387 73.3678 125.441 73.2378V72.3639ZM125.409 71.685C125.409 71.4925 125.331 71.3339 125.175 71.209C125.025 71.0842 124.773 71.0218 124.418 71.0218C124.161 71.0218 123.916 71.053 123.685 71.1154C123.46 71.1726 123.267 71.2402 123.106 71.3183L123.041 70.616C123.224 70.5172 123.439 70.4391 123.685 70.3819C123.938 70.3195 124.206 70.2883 124.491 70.2883C124.893 70.2883 125.215 70.3507 125.457 70.4756C125.704 70.5952 125.881 70.7643 125.988 70.9827C126.101 71.2012 126.158 71.4587 126.158 71.7552V74.416H125.441V73.784C125.291 73.9817 125.108 74.1507 124.893 74.2912C124.679 74.4316 124.394 74.5018 124.04 74.5018C123.68 74.5018 123.379 74.4108 123.138 74.2287C122.896 74.0467 122.776 73.784 122.776 73.4407C122.776 73.243 122.808 73.0739 122.872 72.9335C122.942 72.7878 123.044 72.6682 123.178 72.5745C123.318 72.4809 123.487 72.4029 123.685 72.3405C123.889 72.278 124.128 72.226 124.402 72.1844C124.601 72.1532 124.77 72.1194 124.909 72.083C125.049 72.0465 125.167 72.0049 125.264 71.9581C125.328 71.9269 125.368 71.8931 125.385 71.8567C125.401 71.8151 125.409 71.7578 125.409 71.685Z"
						fill="#4C4C4C"
					/>
					<path
						d="M127.67 73.191V71.0608H126.937V70.3741H127.711L127.799 69.2973H128.379V70.3741H129.595V71.0608H128.379V73.1519C128.379 73.4172 128.443 73.6019 128.572 73.706C128.701 73.81 128.867 73.862 129.071 73.862C129.179 73.862 129.273 73.8542 129.353 73.8386C129.439 73.823 129.522 73.8048 129.603 73.784L129.659 74.4004C129.573 74.4368 129.469 74.4628 129.345 74.4784C129.222 74.494 129.093 74.5018 128.959 74.5018C128.658 74.5018 128.414 74.4498 128.226 74.3458C128.038 74.2418 127.898 74.0935 127.807 73.901C127.716 73.7034 127.67 73.4667 127.67 73.191Z"
						fill="#4C4C4C"
					/>
					<path
						d="M134.166 70.3741V74.416H133.466V73.7528H133.441C133.329 73.9765 133.162 74.1585 132.942 74.299C132.727 74.4342 132.459 74.5018 132.137 74.5018C131.767 74.5018 131.463 74.4264 131.227 74.2756C130.991 74.1247 130.816 73.9218 130.704 73.6669C130.591 73.4068 130.534 73.1129 130.534 72.7852V70.3741H131.243V72.6292C131.243 72.8632 131.278 73.0739 131.348 73.2612C131.418 73.4485 131.533 73.5967 131.694 73.706C131.855 73.81 132.064 73.862 132.322 73.862C132.574 73.862 132.784 73.8048 132.95 73.6903C133.117 73.5759 133.243 73.4199 133.329 73.2222C133.415 73.0245 133.458 72.8034 133.458 72.5589V70.3741H134.166Z"
						fill="#4C4C4C"
					/>
					<path
						d="M135.505 74.416V70.3741H136.198V71.053H136.222C136.335 70.8085 136.512 70.6212 136.753 70.4912C136.995 70.3559 137.296 70.2883 137.655 70.2883L137.623 71.0842C137.113 71.079 136.751 71.196 136.536 71.4353C136.321 71.6694 136.214 72.0101 136.214 72.4575V74.416H135.505Z"
						fill="#4C4C4C"
					/>
					<path
						d="M140.097 70.9671C139.786 70.9671 139.515 71.066 139.284 71.2637C139.058 71.4561 138.924 71.7448 138.881 72.1298H141.281C141.265 71.9113 141.206 71.7162 141.104 71.5446C141.007 71.3729 140.873 71.235 140.701 71.131C140.535 71.0218 140.333 70.9671 140.097 70.9671ZM141.667 74.2834C141.485 74.3614 141.286 74.416 141.071 74.4472C140.862 74.4836 140.647 74.5018 140.427 74.5018C139.949 74.5018 139.539 74.416 139.195 74.2444C138.857 74.0727 138.594 73.8282 138.406 73.5109C138.224 73.1884 138.132 72.8086 138.132 72.3717C138.132 71.9763 138.213 71.6226 138.374 71.3105C138.535 70.9932 138.763 70.7435 139.058 70.5614C139.354 70.3793 139.703 70.2883 140.105 70.2883C140.422 70.2883 140.698 70.3455 140.935 70.46C141.171 70.5744 141.364 70.7279 141.514 70.9203C141.665 71.1128 141.775 71.3235 141.844 71.5524C141.92 71.7812 141.957 72.0127 141.957 72.2468C141.957 72.3092 141.955 72.3743 141.949 72.4419C141.944 72.5043 141.938 72.5589 141.933 72.6058H138.897C138.93 72.8658 139.013 73.0947 139.147 73.2924C139.281 73.4849 139.464 73.6357 139.694 73.745C139.925 73.8542 140.202 73.9088 140.524 73.9088C140.728 73.9088 140.918 73.8932 141.096 73.862C141.278 73.8256 141.447 73.7762 141.603 73.7138L141.667 74.2834Z"
						fill="#4C4C4C"
					/>
					<path
						d="M146.328 69.5392H145.716V71.5914H146.304C146.739 71.5914 147.074 71.5003 147.311 71.3183C147.552 71.131 147.673 70.8605 147.673 70.5068C147.673 70.2103 147.552 69.9762 147.311 69.8045C147.069 69.6276 146.742 69.5392 146.328 69.5392ZM144.983 74.416V68.7979H146.264C146.693 68.7979 147.069 68.8604 147.391 68.9852C147.713 69.1101 147.965 69.2947 148.148 69.5392C148.331 69.7837 148.422 70.0828 148.422 70.4365C148.422 70.6862 148.379 70.9177 148.293 71.131C148.207 71.3443 148.073 71.529 147.89 71.685C147.692 71.8411 147.477 71.9477 147.246 72.0049C147.021 72.0621 146.774 72.1038 146.505 72.1298L148.8 74.416H147.85L145.716 72.1922V74.416H144.983Z"
						fill="#4C4C4C"
					/>
					<path
						d="M151.264 70.9671C150.952 70.9671 150.681 71.066 150.45 71.2637C150.225 71.4561 150.091 71.7448 150.048 72.1298H152.447C152.431 71.9113 152.372 71.7162 152.27 71.5446C152.174 71.3729 152.039 71.235 151.868 71.131C151.701 71.0218 151.5 70.9671 151.264 70.9671ZM152.834 74.2834C152.651 74.3614 152.453 74.416 152.238 74.4472C152.029 74.4836 151.814 74.5018 151.594 74.5018C151.116 74.5018 150.705 74.416 150.362 74.2444C150.024 74.0727 149.76 73.8282 149.573 73.5109C149.39 73.1884 149.299 72.8086 149.299 72.3717C149.299 71.9763 149.379 71.6226 149.54 71.3105C149.701 70.9932 149.93 70.7435 150.225 70.5614C150.52 70.3793 150.869 70.2883 151.272 70.2883C151.588 70.2883 151.865 70.3455 152.101 70.46C152.337 70.5744 152.531 70.7279 152.681 70.9203C152.831 71.1128 152.941 71.3235 153.011 71.5524C153.086 71.7812 153.124 72.0127 153.124 72.2468C153.124 72.3092 153.121 72.3743 153.116 72.4419C153.11 72.5043 153.105 72.5589 153.1 72.6058H150.064C150.096 72.8658 150.179 73.0947 150.313 73.2924C150.448 73.4849 150.63 73.6357 150.861 73.745C151.092 73.8542 151.368 73.9088 151.69 73.9088C151.894 73.9088 152.085 73.8932 152.262 73.862C152.445 73.8256 152.614 73.7762 152.769 73.7138L152.834 74.2834Z"
						fill="#4C4C4C"
					/>
					<path
						d="M153.604 71.0608V70.3741H154.312V69.7031C154.312 69.4222 154.363 69.1855 154.465 68.993C154.573 68.8005 154.723 68.6549 154.916 68.5561C155.115 68.452 155.346 68.4 155.609 68.4C155.748 68.4 155.877 68.4078 155.995 68.4234C156.113 68.4338 156.215 68.4546 156.301 68.4858L156.237 69.0554C156.162 69.0346 156.084 69.0216 156.003 69.0164C155.928 69.0112 155.84 69.0086 155.738 69.0086C155.474 69.0086 155.289 69.071 155.182 69.1959C155.075 69.3207 155.021 69.4872 155.021 69.6953V70.3741H156.197V71.0608H155.021V74.416H154.312V71.0608H153.604Z"
						fill="#4C4C4C"
					/>
					<path
						d="M156.981 74.416V70.3741H157.674V71.053H157.698C157.811 70.8085 157.988 70.6212 158.229 70.4912C158.471 70.3559 158.772 70.2883 159.131 70.2883L159.099 71.0842C158.589 71.079 158.227 71.196 158.012 71.4353C157.797 71.6694 157.69 72.0101 157.69 72.4575V74.416H156.981Z"
						fill="#4C4C4C"
					/>
					<path
						d="M162.338 72.3639C162.241 72.4159 162.107 72.4679 161.935 72.5199C161.769 72.5667 161.581 72.6083 161.371 72.6448C161.055 72.702 160.813 72.7826 160.647 72.8867C160.486 72.9855 160.405 73.1493 160.405 73.3782C160.405 73.5863 160.483 73.7424 160.639 73.8464C160.794 73.9452 160.979 73.9947 161.194 73.9947C161.387 73.9947 161.559 73.9608 161.71 73.8932C161.865 73.8204 161.994 73.7268 162.096 73.6123C162.203 73.4927 162.284 73.3678 162.338 73.2378V72.3639ZM162.305 71.685C162.305 71.4925 162.228 71.3339 162.072 71.209C161.922 71.0842 161.669 71.0218 161.315 71.0218C161.057 71.0218 160.813 71.053 160.582 71.1154C160.357 71.1726 160.163 71.2402 160.002 71.3183L159.938 70.616C160.12 70.5172 160.335 70.4391 160.582 70.3819C160.834 70.3195 161.103 70.2883 161.387 70.2883C161.79 70.2883 162.112 70.3507 162.354 70.4756C162.601 70.5952 162.778 70.7643 162.885 70.9827C162.998 71.2012 163.054 71.4587 163.054 71.7552V74.416H162.338V73.784C162.187 73.9817 162.005 74.1507 161.79 74.2912C161.575 74.4316 161.291 74.5018 160.936 74.5018C160.577 74.5018 160.276 74.4108 160.035 74.2287C159.793 74.0467 159.672 73.784 159.672 73.4407C159.672 73.243 159.704 73.0739 159.769 72.9335C159.839 72.7878 159.941 72.6682 160.075 72.5745C160.214 72.4809 160.384 72.4029 160.582 72.3405C160.786 72.278 161.025 72.226 161.299 72.1844C161.497 72.1532 161.667 72.1194 161.806 72.083C161.946 72.0465 162.064 72.0049 162.16 71.9581C162.225 71.9269 162.265 71.8931 162.281 71.8567C162.297 71.8151 162.305 71.7578 162.305 71.685Z"
						fill="#4C4C4C"
					/>
					<path
						d="M167.321 73.6435L167.377 74.26C167.227 74.3328 167.055 74.39 166.862 74.4316C166.674 74.4784 166.473 74.5018 166.258 74.5018C165.818 74.5018 165.431 74.416 165.098 74.2444C164.766 74.0675 164.505 73.8204 164.317 73.5031C164.129 73.1858 164.035 72.8086 164.035 72.3717C164.035 71.9659 164.121 71.607 164.293 71.2949C164.465 70.9827 164.712 70.7383 165.034 70.5614C165.361 70.3793 165.751 70.2883 166.202 70.2883C166.443 70.2883 166.658 70.3117 166.846 70.3585C167.034 70.4001 167.203 70.4574 167.353 70.5302L167.289 71.2324C167.154 71.1648 167.001 71.1128 166.83 71.0764C166.658 71.04 166.47 71.0218 166.266 71.0218C165.96 71.0218 165.694 71.0816 165.469 71.2012C165.249 71.3209 165.077 71.4847 164.953 71.6928C164.835 71.8957 164.776 72.1324 164.776 72.4029C164.776 72.6942 164.838 72.9517 164.962 73.1754C165.09 73.3938 165.27 73.5629 165.501 73.6825C165.732 73.8022 165.998 73.862 166.298 73.862C166.481 73.862 166.661 73.8438 166.838 73.8074C167.015 73.7658 167.176 73.7112 167.321 73.6435Z"
						fill="#4C4C4C"
					/>
					<path
						d="M168.617 73.191V71.0608H167.884V70.3741H168.657L168.746 69.2973H169.325V70.3741H170.541V71.0608H169.325V73.1519C169.325 73.4172 169.39 73.6019 169.519 73.706C169.647 73.81 169.814 73.862 170.018 73.862C170.125 73.862 170.219 73.8542 170.3 73.8386C170.386 73.823 170.469 73.8048 170.549 73.784L170.606 74.4004C170.52 74.4368 170.415 74.4628 170.292 74.4784C170.168 74.494 170.039 74.5018 169.905 74.5018C169.605 74.5018 169.36 74.4498 169.172 74.3458C168.984 74.2418 168.845 74.0935 168.754 73.901C168.662 73.7034 168.617 73.4667 168.617 73.191Z"
						fill="#4C4C4C"
					/>
					<path
						d="M171.097 72.3951C171.097 71.9737 171.186 71.607 171.363 71.2949C171.54 70.9775 171.787 70.7305 172.104 70.5536C172.42 70.3767 172.785 70.2883 173.199 70.2883C173.618 70.2883 173.985 70.3793 174.302 70.5614C174.619 70.7383 174.866 70.9827 175.043 71.2949C175.22 71.607 175.309 71.9711 175.309 72.3873C175.309 72.7878 175.22 73.1493 175.043 73.4719C174.866 73.7892 174.619 74.0415 174.302 74.2287C173.985 74.4108 173.618 74.5018 173.199 74.5018C172.785 74.5018 172.42 74.4108 172.104 74.2287C171.787 74.0415 171.54 73.7892 171.363 73.4719C171.186 73.1545 171.097 72.7956 171.097 72.3951ZM173.199 73.862C173.478 73.862 173.72 73.8022 173.924 73.6825C174.133 73.5577 174.294 73.3886 174.407 73.1754C174.519 72.9569 174.576 72.7046 174.576 72.4185C174.576 72.1428 174.514 71.9009 174.391 71.6928C174.267 71.4795 174.101 71.3157 173.891 71.2012C173.687 71.0816 173.456 71.0218 173.199 71.0218C172.93 71.0218 172.694 71.0816 172.49 71.2012C172.286 71.3157 172.125 71.4795 172.007 71.6928C171.894 71.9009 171.838 72.1428 171.838 72.4185C171.838 72.6994 171.894 72.9491 172.007 73.1676C172.12 73.386 172.278 73.5577 172.482 73.6825C172.686 73.8022 172.925 73.862 173.199 73.862Z"
						fill="#4C4C4C"
					/>
					<path
						d="M176.342 74.416V70.3741H177.034V71.053H177.058C177.171 70.8085 177.348 70.6212 177.59 70.4912C177.831 70.3559 178.132 70.2883 178.492 70.2883L178.46 71.0842C177.95 71.079 177.587 71.196 177.372 71.4353C177.158 71.6694 177.05 72.0101 177.05 72.4575V74.416H176.342Z"
						fill="#4C4C4C"
					/>
					<path
						d="M179.843 75.9844C179.72 75.9844 179.605 75.9766 179.497 75.961C179.39 75.9454 179.299 75.9246 179.223 75.8986L179.288 75.3133C179.352 75.3342 179.417 75.3498 179.481 75.3602C179.551 75.3706 179.629 75.3758 179.715 75.3758C179.924 75.3758 180.08 75.329 180.182 75.2353C180.289 75.1469 180.386 74.996 180.472 74.7827L180.6 74.455L178.853 70.3741H179.723L180.995 73.7684L182.195 70.3741H183L181.204 74.7671C181.113 75.0012 181.014 75.2093 180.906 75.3914C180.804 75.5734 180.67 75.7165 180.504 75.8205C180.343 75.9298 180.123 75.9844 179.843 75.9844Z"
						fill="#4C4C4C"
					/>
				</svg>
				<p>{dict.footer.isA}</p>
				<Image src={hks} alt="Hakan Kimya Logo" />
				<p>{dict.footer.company}</p>
			</div>
			<div className="workingHours">
				<h4>{dict.footer.workHrs}</h4>
				<p className="dayRow">
					<span>
						{dict.footer.mon} - {dict.footer.fri}
					</span>
					: 08.00 - 17.00
				</p>
				<p className="dayRow">
					<span>{dict.footer.sat}</span>: 08.00 - 17.00
				</p>
				<p className="detail">{dict.footer.closedOnHoliday}</p>
			</div>
			<div className="contact">
				<h4>{dict.footer.contact}</h4>
				<p>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M13.544 13.4486C13.0645 13.698 12.5275 13.813 11.9904 13.813C11.4534 13.813 10.9163 13.698 10.4369 13.4486L2.88 9.63184V16.5749C2.88 17.7641 3.839 18.7231 5.02814 18.7231H18.9527C20.1418 18.7231 21.1008 17.7641 21.1008 16.5749V9.63184L13.544 13.4486Z"
							fill="#9D2B2B"
						/>
						<path
							d="M11.2232 11.9142C11.7027 12.1636 12.2973 12.1636 12.7768 11.9142L21.12 7.69466V7.52204C21.12 6.33289 20.161 5.3739 18.9719 5.3739H5.02814C3.839 5.3739 2.88 6.33289 2.88 7.52204V7.69466L11.2232 11.9142Z"
							fill="#9D2B2B"
						/>
					</svg>
					sales@htr.ltd
				</p>
				<p>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M19.507 3.12H6.4837C5.99998 3.12 5.60928 3.51217 5.60928 3.99772V7.06044H4.25114C3.89765 7.06044 3.59998 7.35924 3.59998 7.71407V9.50687C3.59998 9.8617 3.89765 10.1605 4.25114 10.1605H5.60928V13.8582H4.25114C3.89765 13.8582 3.59998 14.157 3.59998 14.5118V16.2859C3.59998 16.6408 3.89765 16.9396 4.25114 16.9396H5.60928V20.0023C5.60928 20.4878 5.99998 20.88 6.4837 20.88H19.5256C20.0093 20.88 20.4 20.4878 20.4 20.0023V3.99772C20.3814 3.51217 19.9907 3.12 19.507 3.12ZM12.9953 7.04176C14.2232 7.04176 15.2093 8.03154 15.2093 9.2641C15.2093 10.4967 14.2232 11.4864 12.9953 11.4864C11.7674 11.4864 10.7814 10.4967 10.7814 9.2641C10.7814 8.03154 11.7674 7.04176 12.9953 7.04176ZM16.0093 16.6408C15.0604 16.8462 14.0558 16.9582 12.9953 16.9582C11.9349 16.9582 10.9302 16.8462 9.98137 16.6408C9.73951 16.5847 9.57207 16.3606 9.60928 16.0992L9.83253 14.1756C9.94416 13.1859 10.6697 12.4015 11.6 12.1774C12.0279 12.3828 12.493 12.5136 12.9953 12.5136C13.4976 12.5136 13.9628 12.3828 14.3907 12.1774C15.3209 12.4202 16.0279 13.1859 16.1581 14.1756L16.3814 16.0992C16.4186 16.342 16.2511 16.5847 16.0093 16.6408Z"
							fill="#9D2B2B"
						/>
					</svg>
					+90 &#40;222&#41; 411 21 32
				</p>
				<p>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M11.99 2.64001C8.41342 2.64001 5.52002 5.51244 5.52002 9.06308C5.52002 13.0725 9.35779 18.7376 11.126 21.1512C11.568 21.7496 12.432 21.7496 12.8741 21.1512C14.6423 18.7376 18.48 13.0725 18.48 9.06308C18.4599 5.51244 15.5665 2.64001 11.99 2.64001ZM12.0302 12.135C10.3022 12.135 8.89565 10.7387 8.89565 9.02318C8.89565 7.30771 10.3022 5.91139 12.0302 5.91139C13.7582 5.91139 15.1647 7.30771 15.1647 9.02318C15.1647 10.7586 13.7582 12.135 12.0302 12.135Z"
							fill="#9D2B2B"
						/>
					</svg>
					Hisar Mah. 1702. Sok. No:8 Tepebaşı Eskişehir
				</p>
			</div>

			<div className="legal">
				<div className="row">
					<Link href="/kvkk">KVKK</Link>
					<span className="spacer">|</span>
					<Link href="/terms-of-service">{dict.footer.termsOfService}</Link>
					<span className="spacer">|</span>
					<Link href="/privacy-policy">{dict.footer.privacyPolicy}</Link>
					<span className="spacer">|</span>
					<Link href="/cookie-policy">{dict.footer.cookiePolicy}</Link>
				</div>
				<p>
					Copyright 2023 © HTR High Temperature Refractory Kimya İç ve Dış Tic.
					Ltd. Şti.
				</p>
			</div>

            <div className="ad">
				<p>{dict.footer.websiteBy}</p>
				<Image src={hkd} alt="Hakan Kayra Doğan Logo"/>
                <Link href="https://hkayrad.me" className="hkd">Hakan Kayra Doğan</Link>
			</div>
		</footer>
	);
}
